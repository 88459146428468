import get from "./../utils/get";

const getGeo = async localidad => {
  try {
    if (localidad && localidad._id) {
      const provincia = localidad.provincia ? localidad.provincia.nombre : "";
      const apikey = process.env.VUE_APP_HERE_APP_KEY_REST;
      const searchtext = encodeURIComponent(
        `${localidad.nombre}, ${provincia}, argentina`
      );
      const response = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${apikey}&searchtext=${searchtext}`
      );
      const rtaGeoJson = await response.json();
      const Location = get(rtaGeoJson, "Response.View.0.Result.0.Location");
      if (typeof Location === "undefined") return void 0;
      const geoJson = {
        NavigationPosition: get(Location, "NavigationPosition.0"),
        DisplayPosition: Location.DisplayPosition,
        Address: Location.Address
      };
      return geoJson.DisplayPosition;
    }
  } catch (e) {
    console.error(e);
    throw Error("Error al obtener las coordenadas");
  }
};

export default getGeo;
