<template>
  <ion-page>
    <ion-content :fullscreen="true" color="dark" :scroll-events="true">
      <div id="container">
        <ion-row style="position:relative; left: 25px; top:8px; z-index: 1;">
          <ion-icon
            style="margin-right: 4px; margin-top: 1px;"
            color="warning"
            :md="helpIcon"
            :ios="helpIcon"
          ></ion-icon>
          <ion-text style="white-space:normal">
            Buscar la posición y hacer doble click para agregar un Lote
          </ion-text>
        </ion-row>
        <ion-row
          style="position:relative; top:0px; z-index: 1;"
          class="ion-align-items-center"
        >
          <ion-col>
            <ion-searchbar
              id="colSearchbar"
              placeholder="Ingrese una Localidad"
              @ionChange="filtroLocalidad = $event.target.value"
            ></ion-searchbar>
          </ion-col>
          <ion-col size="auto">
            <ion-button
              fill="outline"
              color="light"
              class="ion-float-end"
              @click="goToLotes"
            >
              Lotes
            </ion-button>
          </ion-col>
        </ion-row>
        <div
          v-if="displayLocalidadesList"
          id="colList"
          style="position:absolute; top:87px; z-index: 1;"
        >
          <ion-list
            lines="full"
            v-for="l in localidades"
            :value="l"
            :key="l._id"
          >
            <ion-item
              class="itemBorder reduceLateralMargin"
              button="true"
              type="button"
              style="--background: rgba(var(--ion-color-light-rgb), 0.82)"
              @click="setLocalidad(l)"
            >
              <ion-label
                style="white-space: normal; font-size: 12px !important"
              >
                {{
                  capitalize(l.nombre) + ", " + capitalize(l.provincia.nombre)
                }}
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-row
            v-if="filtroLocalidad !== '' && filtroLocalidad.length >= 3"
            class="ion-float-end"
          >
            <ion-col size="auto">
              <ion-button
                @click="page--"
                size="small"
                :disabled="page === 1"
                style="--background: rgba(var(--ion-color-light-rgb), 0.625); --color: black;"
              >
                &#60;
              </ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button
                @click="page++"
                size="small"
                :disabled="lastPage"
                style="--background: rgba(var(--ion-color-light-rgb), 0.625); --color: black;"
              >
                &#62;
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
        <map-view
          :localidad="selectedLocalidad"
          @createLote="handleCrearLote"
          v-model:mapRefresh="mapRefresh"
          style="z-index: -1"
        />
      </div>
    </ion-content>
    <ion-modal
      id="modal"
      :is-open="modalCreate"
      @didDismiss="modalCreate = false"
      :backdropDismiss="false"
    >
      <LoteForm
        :mapCoords="selectedCoords"
        :error="error"
        :persona="user"
        @cancel="cancelCreate"
        @submit="submitCreate"
        class="modal-wrapper"
      />
    </ion-modal>
  </ion-page>
</template>

<script>
import {
  alertController,
  IonContent,
  IonSearchbar,
  IonList,
  IonCol,
  IonRow,
  IonIcon,
  IonPage,
  IonText,
  IonButton,
  IonModal
} from "@ionic/vue";
import LoteForm from "../../components/LoteForm.vue";
import MapView from "../../components/MapView.vue";
import isLatLong from "validator/lib/isLatLong";
import { mapActions, mapGetters } from "vuex";
import showToast from "../../utils/toast";
import { helpCircleOutline } from "ionicons/icons";
import capitalizeWords from "@/utils/capitalizeWords.js";
import http from "@/api/http";
import { Lotes } from "@/api";

export default {
  components: {
    IonContent,
    IonList,
    IonButton,
    IonCol,
    IonSearchbar,
    IonPage,
    IonIcon,
    IonRow,
    IonText,
    IonModal,
    MapView,
    LoteForm
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({
    forms: {},
    filtroLocalidad: "",
    selectedLocalidad: undefined,
    displayLocalidadesList: false,
    localidades: [],
    querySector: {},
    sector: { rubros: [] },
    rubro: void 0,
    modalCreate: false,
    selectedCoords: {},
    mapRefresh: false,
    totalItems: 0,
    limit: 10,
    skip: 0,
    page: 1,
    helpIcon: helpCircleOutline
  }),

  computed: {
    ...mapGetters(["isAdmin", "user"]),
    idProductor() {
      return this.$route.params.idProductor;
    },
    totalItemsNow() {
      if (this.localidades.length && this.localidades.length > 0) {
        return this.localidades.length;
      }
      return 0;
    },
    lastPage() {
      let roundUppedTotalItems = this.totalItems;
      if (this.totalItems % 10 !== 0) {
        roundUppedTotalItems = parseInt(this.totalItems / 10, 10) * 10 + 10;
      }
      return this.page * this.limit === roundUppedTotalItems;
    }
  },

  async mounted() {
    await this.getMe();
  },

  watch: {
    async filtroLocalidad(val) {
      this.page = 1;
      this.totalItems = 0;
      if (val !== "" && val.length >= 3) {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          await this.getLocalidades();
          if (this.localidades && this.localidades.length) {
            this.displayLocalidadesList = true;
          }
        }, 500);
      } else {
        this.localidades = [];
      }
    },
    async page(val) {
      this.skip = this.limit * (val - 1);
      await this.getLocalidades();
    }
  },

  methods: {
    ...mapActions(["getMe"]),

    goToLotes() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-lotes-productor",
          params: { idProductor: this.idProductor }
        });
      } else {
        this.$router.push({ name: "persona-lotes" });
      }
    },

    capitalize(str) {
      return capitalizeWords(str);
    },

    handleCrearLote(coords) {
      if (!isLatLong(coords.lat + "," + coords.lng)) {
        // esto nunca pasaría clickeando el mapa pero no está mal checkearlo.
        return showToast({
          title: "Error",
          message: "Coordenadas inválidas",
          color: "danger",
          pos: "middle"
        });
      }
      this.selectedCoords = { latitud: coords.lat, longitud: coords.lng };
      this.modalCreate = true;
    },

    setLocalidad(localidad) {
      this.selectedLocalidad = localidad;
      this.filtroLocalidad = "";
      this.displayLocalidadesList = false;
    },

    async getLocalidades() {
      if (this.filtroLocalidad && this.filtroLocalidad !== "") {
        const { data, error } = await http.get(
          "/api/get-localidades-paginadas",
          {
            params: {
              searchtext: this.filtroLocalidad.toLowerCase(),
              limit: this.limit,
              skip: this.skip,
              totalCount: this.totalItems
            }
          }
        );
        if (error) {
          const alert = await alertController.create({
            header: "Error",
            message: "Error obteniendo localidades.",
            buttons: [
              {
                text: "Aceptar"
              }
            ]
          });
          return alert.present();
        }
        this.localidades = data[0];
        if (data[1]) {
          this.totalItems = data[1];
        }
      }
    },

    goTo(item) {
      this.$router.push({
        name: "lote",
        params: { idLote: item._id }
      });
    },

    refreshMap() {
      this.mapRefresh = true;
    },

    cancelCreate() {
      this.selectedCoords = {};
      this.modalCreate = false;
    },

    async submitCreate({ lote }) {
      this.error = "";
      const { data, error } = await Lotes.save(lote);
      if (error || !data) {
        this.error = error;
      } else {
        const self = this;
        this.success = true;
        const alert = await alertController.create({
          header: "Lotes",
          message: "Lote Creado",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                self.refreshMap();
                this.cancelCreate();
                if (this.isAdmin && this.idProductor) {
                  this.$router.push({
                    name: "admin-productor-lote",
                    params: {
                      idProductor: this.idProductor,
                      tab: "Sl",
                      idLote: data._id
                    }
                  });
                } else {
                  this.$router.push({
                    name: "lote",
                    params: {
                      tab: "Sl",
                      idLote: data._id
                    }
                  });
                }
                self.refreshMap();
                this.cancelCreate();
              }
            }
          ]
        });
        this.modalEdit = false;
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-list {
  background-color: rgba(var(--ion-color-medium-rgb), 0.65);
  padding-top: 0px;
  padding-bottom: 4px;
}

.reduceLateralMargin {
  margin-left: 4px;
  margin-right: 4px;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.negrita {
  color: black;
  font-size: 125%;
  margin-left: 0px;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.margen8pxArriba {
  margin-top: 8px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

@media only screen and (min-width: 1024px) {
  #colSearchbar {
    width: 43%;
  }
  #colList {
    margin-left: 12px;
    width: 38.3%;
  }
}

ion-card {
  margin-top: 16px;
}
</style>
