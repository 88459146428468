import { toastController } from "@ionic/vue";

const showToast = async (params = {}) => {
  const toast = await toastController.create({
    header: params.title || "",
    message: params.message || "",
    position: params.pos || "top",
    buttons: params.buttons || [{ text: "Ok", role: "cancel" }],
    color: params.color || "warning"
  });
  return await toast.present();
};

export default showToast;
