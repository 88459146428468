<template>
  <div
    style="width: 100%; height: 91vh; "
    :class="{ 'map-loading': !ready }"
    id="mapDiv"
  >
    <div style="width: 100%; height: 100%" ref="mapContainer"></div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { alertController } from "@ionic/vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { Lotes } from "@/api";
import getGeo from "./../utils/getGeo";
const H = window.H;
// import capitalizeWords from "./../utils/capitalizeWords";

// Mover icons
// https://developer.here.com/api-explorer/maps-js/v3.0/markers/draggable-marker
const mapMarkerLoteIcon = fill => `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="40" height="40">
<path fill="${fill}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
<path d="M0 0h24v24H0z" fill="none"/>
</svg>`;

const platform = new H.service.Platform({
  apikey: process.env.VUE_APP_HERE_APP_KEY_MAPA,
  useCIT: true,
  useHTTPS: true
});
const pixelRatio = window.devicePixelRatio || 1;
const defaultLayers = platform.createDefaultLayers({
  lang: "es",
  tileSize: pixelRatio === 1 ? 256 : 512,
  ppi: pixelRatio === 1 ? undefined : 320
});
// eslint-disable-next-line no-unused-vars
var ui;

export default {
  props: {
    mapRefresh: {
      type: Boolean,
      default: false
    },
    localidad: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    ready: false,
    lotes: [],
    map: null,
    marker: [],
    userLocation: {
      latitude: null, // -33.03,
      longitude: null, //-61.165,
      accuracy: 73
    }
  }),
  computed: {
    ...mapState(["user", "admin_user"]),
    ...mapGetters(["isAdmin", "isAdminRegional"]),
    persona() {
      return this.user; // por ahora...
    }
  },

  methods: {
    ...mapActions(["getMe", "getUserAdmin"]),
    moveMapTo(map) {
      this.map.setCenter(map);
      this.map.setZoom(14);
    },

    async getLotesPropios() {
      const pers = this.persona;
      if (!pers._id) {
        return;
      }
      const query = { persona: pers._id, deleted: false };

      const { data, error } = await Lotes.get({
        query: query,
        populate: "persona",
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lotes.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      this.lotes = data;
    },

    async getAllLotes() {
      const query = { persona: { $ne: this.persona._id }, deleted: false };
      if (this.isAdminRegional) {
        query.regional = this.admin_user.regional;
      }
      const { data, error } = await Lotes.get({
        query: query,
        populate: "persona",
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lotes.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      const newLotes = data.map(lote => {
        return { ...lote, _color: "#ffc409" };
      });
      this.lotes.push(...newLotes);
    },

    async goToLocation(location) {
      const coords = await getGeo(location);
      if (coords && coords.Latitude && coords.Longitude) {
        return this.moveMapTo({
          lat: coords.Latitude,
          lng: coords.Longitude
        });
      }
      const alert = await alertController.create({
        header: "Error",
        message:
          "Ocurrió un error al obtener la ubicación de la localidad seleccionada.",
        buttons: [
          {
            text: "Aceptar"
          }
        ]
      });
      alert.present();
    },

    centerMapInUser() {
      navigator.geolocation.getCurrentPosition(
        // por algún motivo no pide permiso, cambiar por el plugin de ionic
        async location => {
          // Obtiene las coordenadas actuales del usuario
          this.userLocation = location.coords;
          // Como 2da opción, usa la localidad del usuario ingresada en el registro del mismo y obteniene
          // las coordenadas de la misma
          const loc = this.user.localidad;
          const coords = await getGeo(loc);
          if (!this.userLocation.latitude || !this.userLocation.longitude) {
            return this.moveMapTo({
              lat: coords.Latitude,
              lng: coords.Longitude
            });
          }
          this.moveMapTo({
            lat: this.userLocation.latitude,
            lng: this.userLocation.longitude
          });
        },
        async () => {
          const loc = this.user.localidad;
          const coords = await getGeo(loc);
          this.moveMapTo({
            lat: coords.Latitude,
            lng: coords.Longitude
          });
        }
      );
    },

    async changeMapBubble() {
      if (this.marker.length > 0) {
        this.map.removeObjects(this.marker);
        this.marker = [];
      }
      const self = this;
      this.moveMapTo({
        // primero centro en rosario para que no salga planisferio
        lat: "-32.947735",
        lng: "-60.6546636"
      });
      self.map.addEventListener("dbltap", async function(e) {
        const coords = self.map.screenToGeo(
          e.currentPointer.viewportX,
          e.currentPointer.viewportY
        );
        const alert = await alertController.create({
          header: "Crear nuevo lote",
          message: "Desea crear un nuevo lote en la ubicación elegida?",
          buttons: [
            {
              text: "Cancelar",
              role: "cancel"
            },
            {
              text: "Aceptar",
              handler: () => {
                self.$emit("createLote", coords);
              }
            }
          ]
        });
        alert.present();
      });
      if (this.lotes && !this.lotes.length) {
        return this.centerMapInUser();
      }
      const lotesConCoords = this.lotes.filter(
        lote =>
          lote.coordenadas &&
          lote.coordenadas.longitud &&
          lote.coordenadas.latitud
      );
      if (!lotesConCoords || !lotesConCoords.length) {
        return this.centerMapInUser();
      }
      lotesConCoords.forEach(lote => {
        const _geo = {
          lat: lote.coordenadas.latitud,
          lng: lote.coordenadas.longitud
        };
        const icon = new H.map.Icon(mapMarkerLoteIcon(lote._color));
        const marker = new H.map.Marker(_geo, { icon });
        const url =
          lote.persona && lote.persona._id === this.persona._id
            ? `/lotes/Sl/${lote._id}`
            : this.isAdmin
            ? `/productores/${lote.persona._id}/lotes/Sl/${lote._id}`
            : "";
        marker.addEventListener("tap", function() {
          const tooltip = new H.ui.InfoBubble(_geo, {
            content:
              `<div><b>${lote.nombre}</b>` +
              `<p>${lote.localidad}</p>
              <a href="${url}">Ver más</a></div>`
          });
          ui.addBubble(tooltip);
        });
        self.marker.push(marker);
      });
      self.map.addObjects(this.marker);
      if (lotesConCoords.length) {
        self.moveMapTo({
          lat: lotesConCoords[lotesConCoords.length - 1].coordenadas.latitud,
          lng: lotesConCoords[lotesConCoords.length - 1].coordenadas.longitud
        });
      }
      this.centerMapInUser();
      self.map.addEventListener("pointermove", function(e) {
        self.map.getElement().style.cursor =
          e.target === self.map ? "" : "pointer";
      });
      this.$emit("update:mapRefresh", false);
    },

    buidAndmountedMap() {
      this.map = new H.Map(
        this.$refs.mapContainer,
        defaultLayers.raster.satellite.map,
        {
          pixelRatio: pixelRatio
        }
      );
      // eslint-disable-next-line no-unused-vars
      ui = H.ui.UI.createDefault(this.map, defaultLayers, "es-ES");
      // eslint-disable-next-line no-unused-vars
      const ms = new H.ui.MapSettingsControl({
        baseLayers: [
          {
            label: "Normal",
            layer: defaultLayers.raster.normal.map
          },
          {
            label: "Satélite",
            layer: defaultLayers.raster.satellite.map
          },
          {
            label: "Terreno",
            layer: defaultLayers.raster.terrain.map
          }
        ],
        layers: [
          {
            label: "layer.traffic",
            layer: defaultLayers.vector.normal.traffic
          },
          {
            label: "layer.incidents",
            layer: defaultLayers.vector.normal.trafficincidents
          }
        ]
      });

      // eslint-disable-next-line no-unused-vars
      ui = H.ui.UI.createDefault(this.map, defaultLayers, "es-ES");
      //ui.addControl("customized", ms);
      const mapEvents = new H.mapevents.MapEvents(this.map);
      // eslint-disable-next-line
      const behavior = new H.mapevents.Behavior(mapEvents);
      behavior.disable(H.mapevents.Behavior.DBLTAPZOOM); //desactivo zoom con rclick en el mapa
      this.changeMapBubble();
      this.ready = true;
    },

    async getLotesToShow() {
      await this.getLotesPropios();
      this.lotes = this.lotes.map(lote => {
        return { ...lote, _color: "#5260ff" };
      });
      if (this.isAdmin) {
        await this.getAllLotes();
      }
      this.lotes = this.lotes.filter(lote => lote.persona !== null);
    }
  },

  async mounted() {
    await this.getMe();
    await this.getLotesToShow();
    setTimeout(this.buidAndmountedMap, 500);
  },
  watch: {
    async mapRefresh(val) {
      if (val) {
        await this.getLotesToShow();
        this.changeMapBubble();
      }
    },
    async localidad(val) {
      if (val && val._id) {
        await this.goToLocation(val);
      }
    }
  },
  beforeUnmount() {
    if (this.map) {
      this.map.dispose();
    }
  }
};
</script>

<style>
.map-loading
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(2) {
  background: rgba(0, 0, 0, 0.1);
}
</style>
